/* eslint-disable no-restricted-globals */
import { ILangState } from '@contextTypes';
import {
  IPage,
  IPost,
  IPagination,
  ILocation,
  ICategory
} from '@standardTypes';
import { usePostsWithCategory } from '@utils';

import React from 'react';

import { LangCtx, useContextState } from '@components/contexted';
import { Layout } from '@components/layout';
import {
  BlogHero,
  CategoryVideoPosts,
  SectionsWithBackround
} from '@components/organisms/standard';

interface BlogProps {
  pageContext: {
    page: IPage;
    posts: Array<IPost>;
    pagination: IPagination;
    categories: ICategory[];
    translationUrls: Record<string, string>;
  };
  location: ILocation;
}

const VideoAndPodcastPage: React.FC<BlogProps> = ({
  pageContext,
  location
}): JSX.Element => {
  const { page, pagination, posts, translationUrls } = pageContext;
  const { skip, limit, currentPage } = pagination;
  const { pathname } = location;

  const currentPagePosts = posts.slice(skip, limit * currentPage);
  const backgroundTiles = currentPagePosts.length > 7 ? 4 : 3;

  const { language } = useContextState<ILangState>(LangCtx, 'language');

  const similarPosts = posts.filter(
    (post) => post.language.code.toLowerCase() === language
  );

  const popularPostArticle: IPost[] = usePostsWithCategory(
    similarPosts,
    'articles'
  ).slice(0, 2);

  const popularPostCaseStudy: IPost[] = usePostsWithCategory(
    similarPosts,
    'case-study'
  ).slice(0, 1);

  const popularPostTestersEye: IPost[] = usePostsWithCategory(
    similarPosts,
    'testers-eye'
  ).slice(0, 1);

  const popularPostVideo: IPost[] = usePostsWithCategory(
    similarPosts,
    'video-and-podcast'
  ).slice(0, 1);

  const categoryPosts = [
    popularPostArticle,
    popularPostCaseStudy,
    popularPostTestersEye,
    popularPostVideo
  ];

  return (
    <Layout {...page} translationUrls={translationUrls}>
      <BlogHero activeLink="videoAndPodcast" />
      <SectionsWithBackround numberOfBackgrounds={backgroundTiles}>
        <CategoryVideoPosts
          posts={currentPagePosts}
          pagination={pagination}
          pathname={pathname}
          categoryPosts={categoryPosts}
        />
      </SectionsWithBackround>
    </Layout>
  );
};
export default VideoAndPodcastPage;
